














































































































































































































































































































































































  import { debounceInput, screenSizeIsMobile } from '@/shared/helpers'
  import { IUser, User } from '@/shared/model/user.model'
  import { IVehicle, Vehicle } from '@/shared/model/vehicle.model'
  import { IVehicleManufacturer } from '@/shared/model/vehicleManufacturer.model'
  import userService from '@/shared/services/userService'
  import vehicleService from '@/shared/services/vehicleService'
  import axios from 'axios'
  import Vue from 'vue'
  import { mapActions, mapGetters } from 'vuex'

  export default Vue.extend({
    name: 'VehicleTables',
    components: {
      VehicleWizard: () => import('./VehicleWizard.vue'),
    },

    data () {
      return {
        options: {} as any,
        optionsManufacturers: {} as any,

        dialog: false,
        dialogManufacturer: false,

        vehicleManufacturerSearch: '',
        vehicleSearch: '',
        vehicleManufacturerDebounce: undefined as number | undefined,
        vehicleDebounce: undefined as number | undefined,

        selectedVehicle: new Vehicle(),
        selectedVehicleManufacturer: { _id: undefined, name: '' },

        selectedVehicleManufacturerForFilter: null as string | null,
        filteredVehicles: [] as Vehicle[],
      }
    },

    computed: {
      ...mapGetters({
        account: 'account',
        vehicles: 'vehicleStore/getList',
        totalVehicles: 'vehicleStore/getTotal',
        vehicleManufacturers: 'vehicleManufacturerStore/getList',
        totalVehicleManufacturers: 'vehicleManufacturerStore/getTotal',
        updateVehicleError: 'vehicleStore/getError',
        updateVehicleManufacturerError: 'vehicleManufacturerStore/getError',
      }),
      userIsSystemAdmin () {
        return userService.userIsSystemAdmin()
      },
      vehicleHeaders (): any {
        return [
          {
            text: this.$t('name'),
            value: 'name',
          },
          {
            text: this.$t('manufacturer'),
            value: 'manufacturer',
          },
          {
            sortable: false,
            text: this.$t('actions'),
            value: 'actions',
            align: 'end',
          },
        ]
      },
      vehicleManufacturerHeaders (): any {
        return [
          {
            text: this.$t('name'),
            value: 'name',
          },
          {
            sortable: false,
            text: this.$t('actions'),
            value: 'actions',
            align: 'end',
          },
        ]
      },
    },
    watch: {
      options: {
        async handler () {
          this.getAllVehiclesWithVDataTable()
        },
      },
      optionsManufacturers: {
        async handler () {
          this.getAllVehicleManufacturersWithVDataTable()
        },
      },
      vehicleManufacturerSearch: {
        async handler () {
          this.vehicleManufacturerDebounce = debounceInput(
            this.vehicleManufacturerDebounce,
            this.getAllVehicleManufacturersWithVDataTable,
          )
          this.optionsManufacturers.page = 1
        },
      },
      vehicleSearch: {
        async handler () {
          this.vehicleDebounce = debounceInput(this.vehicleDebounce, this.getAllVehiclesWithVDataTable)
          this.options.page = 1
        },
      },
      vehicles: {
        async handler () {
          this.filterVehiclesBySelectedManufacturer()
        },
      },
    },
    methods: {
      ...mapActions('vehicleStore', {
        createVehicle: 'createItem',
        updateVehicle: 'updateItem',
        deleteVehicle: 'deleteItem',
      }),
      ...mapActions('vehicleManufacturerStore', {
        createVehicleManufacturer: 'createItem',
        updateVehicleManufacturer: 'updateItem',
        deleteVehicleManufacturer: 'deleteItem',
      }),
      async getAllVehicleManufacturersWithVDataTable () {
        const query = {
          // we pull all manufacturers at once and do the paging locally
          // this way we can map the ids from the vehiclestable to all manufacturers
          l: 0,
          lo: this.optionsManufacturers.page,
          sf: this.optionsManufacturers.sortBy[0],
          so: this.optionsManufacturers.sortDesc[0] ? 'desc' : 'asc',
          search: { search: this.vehicleManufacturerSearch },
        }
        this.$store.dispatch('vehicleManufacturerStore/getList', query)
      },
      async getAllVehiclesWithVDataTable () {
        const query = {
          l: this.options.itemsPerPage,
          lo: this.options.page,
          sf: this.options.sortBy[0],
          so: this.options.sortDesc[0] ? 'desc' : 'asc',
          search: { search: this.vehicleSearch },
        }
        if (this.selectedVehicleManufacturerForFilter) {
          vehicleService.getVehiclesByManufacturer(this.$store, query, this.selectedVehicleManufacturerForFilter)
        } else {
          this.$store.dispatch('vehicleStore/getList', query)
        }
      },
      async clickSaveVehicleManufacturer (validManufacturer: any) {
        if (validManufacturer) {
          if (this.selectedVehicleManufacturer._id) {
            await this.updateVehicleManufacturer({
              id: this.selectedVehicleManufacturer._id,
              item: this.selectedVehicleManufacturer,
            })
          } else {
            await this.createVehicleManufacturer({
              item: this.selectedVehicleManufacturer,
            })
          }
          this.dialogManufacturer = false
          this.selectedVehicleManufacturer = { _id: undefined, name: '' }

          if (this.updateVehicleManufacturerError) {
            this.$toast.error(this.$t('dialog.save-vehicle-manufacturer-error').toString())
          } else {
            this.$toast(this.$t('dialog.save-vehicle-manufacturer-success').toString())
          }
        }
      },
      clickUpdateVehicleManufacturer (item: any) {
        this.selectedVehicleManufacturer = item
        this.dialogManufacturer = true
      },
      clickDeleteVehicleManufacturer (vehicle: IVehicle) {
        this.$confirm(
          `${this.$t('dialog.do-you-really-want-the-vehicle-manufacturer-to')}
<strong>${vehicle.name}</strong>
${this.$t('dialog.delete')}`,
          {
            color: 'error',
            buttonTrueText: this.$t('yes').toString(),
            buttonFalseText: this.$t('no').toString(),
            title: this.$t('tooltip.delete-vehicle-manufacturer').toString(),
          },
        ).then((res) => {
          if (res) {
            if (vehicle._id) {
              this.deleteVehicleManufacturer({
                id: vehicle._id,
                item: vehicle,
              }).then(() => {
                this.getAllVehicleManufacturersWithVDataTable()
              })
            } else {
              console.error('Tried to delete vehicle without id, this should not happen')
            }
          }
        })
      },
      clickUpdateVehicle (item: Vehicle) {
        this.selectedVehicle = item
        this.dialog = true
      // this.$router.push(`/app/vehicl/${item._id}`, () => {})
      },
      clickViewVehicle (item: Vehicle) {
        if (item._id) {
          this.$router.push(`/app/vehicle/${item._id}`, () => {})
        }
      },
      clickDeleteVehicle (vehicle: IVehicle) {
        this.$confirm(
          `${this.$t('dialog.do-you-really-want-the-vehicle-to')}
<strong>${vehicle.name}</strong>
${this.$t('dialog.delete')}`,
          {
            color: 'error',
            buttonTrueText: this.$t('yes').toString(),
            buttonFalseText: this.$t('no').toString(),
            title: this.$t('tooltip.delete-vehicle').toString(),
          },
        ).then((res) => {
          if (res) {
            if (vehicle._id) {
              this.deleteVehicle({ id: vehicle._id, item: vehicle }).then(() => {
                this.getAllVehiclesWithVDataTable()
              })
            } else {
              console.error('Tried to delete vehicle without id, this should not happen')
            }
          }
        })
      },
      filterVehiclesBySelectedManufacturer () {
        if (this.selectedVehicleManufacturerForFilter) {
          this.filteredVehicles = this.vehicles.filter(
            (x: Vehicle) => x.manufacturer === this.selectedVehicleManufacturerForFilter,
          )
          this.selectedVehicle.manufacturer = this.selectedVehicleManufacturerForFilter.toString()
        } else {
          this.filteredVehicles = this.vehicles
          this.selectedVehicle.manufacturer = undefined
        }
      },
      selectVehicleManufacturerForFiltering (item: any) {
        if (item && item._id) {
          this.selectedVehicleManufacturerForFilter = item._id
        } else {
          this.selectedVehicleManufacturerForFilter = null
        }
        this.resetVehicleFilters()
        this.getAllVehiclesWithVDataTable()
      // this.filterVehiclesBySelectedManufacturer()
      },
      resetVehicleFilters () {
        this.vehicleSearch = ''
        this.options.page = 1
      },
      autocompleteFilter (item: any, queryText: string, itemText: string) {
        return item.name.toLowerCase().includes(queryText.toLowerCase())
      },
      manufacturerName (id: string) {
        const item = this.vehicleManufacturers.find((x: any) => x._id === id)
        if (item) return item.name
        else return '-'
      },
      getHighlightClass (id: string | null) {
        if (this.selectedVehicleManufacturerForFilter === id) {
          return 'highlight-row'
        } else {
          return ''
        }
      },
      async clickAcceptUserVehicle (vehicle: IVehicle) {
        axios.put('/api/accept-vehicle/' + vehicle._id).then((result) => {
          if (result && result.data) {
            this.$toast(this.$t('dialog.vehicle-accepted').toString())
            this.getAllVehiclesWithVDataTable()
          }
        })
      },
      async clickAcceptUserVehicleManufacturer (manufacturer: IVehicleManufacturer) {
        axios.put('/api/accept-vehiclemanufacturer/' + manufacturer._id).then((result) => {
          if (result && result.data) {
            this.$toast(this.$t('dialog.vehicle-manufacturer-accepted').toString())
            this.getAllVehicleManufacturersWithVDataTable()
          }
        })
      },
      isMobile () {
        return screenSizeIsMobile()
      },
      createNewVehicle () {
        this.selectedVehicle = new Vehicle()
        this.dialog = true
      },
    },
  })

export interface IVehicleDetails {
  content: string
  pdfs: any[]
}

export interface IVehicle {
  name: string
  _id?: string
  type?: string
  createdByUser?: string
  manufacturer?: string
  details?: IVehicleDetails
  createdAt?: Date
  updatedAt?: Date
}

export class Vehicle implements IVehicle {
  constructor (
    public name: string = '',
    public _id?: string,
    public manufacturer?: string,
    public createdByUser?: string,
    public details: IVehicleDetails = { content: '', pdfs: [] },
    public createdAt?: Date,
    public updatedAt?: Date,
  ) {
    return this
  }
}

import { types } from '@/store/utils/resource'
import axios from 'axios'
import { IVehicle } from '../model/vehicle.model'
import { IVehicleManufacturer } from '../model/vehicleManufacturer.model'

class VehicleService {
  async getVehiclesByManufacturer (store: any, { l, lo, sf, so, search = {} }: any, manufacturerId: string) {
    store.commit(`vehicleStore/${types.GET_LIST.REQUEST}`, { l, lo, search })
    try {
      // The actual limitOffset is calculated in the api
      const result = await axios.get('/api/vehicleByManufacturer/' + manufacturerId, {
        params: { l: l, lo: lo - 1, sf, so, ...search },
      })
      store.commit(`vehicleStore/${types.GET_LIST.SUCCESS}`, result)
    } catch (err) {
      store.commit(`vehicleStore/${types.GET_LIST.FAILURE}`)
    } finally {
    }
  }

  async getVehicleManufacturs (store: any, { l, lo, sf, so, search = {} }: any) {
    try {
      // The actual limitOffset is calculated in the api
      const result = await axios.get('/api/vehicleManufacturer', {
        params: { l: l, lo: lo - 1, sf, so, ...search },
      })
      if (result.data) {
        return result.data
      }
    } catch (err) {
      console.log(err)
    } finally {
    }
  }

  async getVehicle (id: string) {
    try {
      // The actual limitOffset is calculated in the api
      const result = await axios.get(`/api/vehicle/${id}`)
      if (result.data) {
        return result.data
      }
    } catch (err) {
      console.log(err)
    } finally {
    }
  }

  async getVehicleManufacturer (id: string) {
    try {
      // The actual limitOffset is calculated in the api
      const result = await axios.get(`/api/vehicleManufacturer/${id}`)
      if (result.data) {
        return result.data
      }
    } catch (err) {
      console.log(err)
    } finally {
    }
  }

  async createVehicleByUser (store: any, vehicle: IVehicle): Promise<IVehicle | null> {
    return new Promise((resolve) => {
      store.commit(`vehicleStore/${types.CREATE_ITEM.REQUEST}`)
      try {
        axios
          .post('/api/vehicle', {
            ...vehicle,
          })
          .then((result) => {
            store.commit(`vehicleStore/${types.CREATE_ITEM.SUCCESS}`, result)
            if (result && result.data) {
              resolve(result.data)
            } else {
              resolve(null)
            }
          })
      } catch (err) {
        store.commit(`vehicleStore/${types.CREATE_ITEM.FAILURE}`)
      } finally {
      }
    })
  }

  async createVehicleManufacturerByUser (
    store: any,
    vehicleManufacturer: IVehicle,
  ): Promise<IVehicleManufacturer | null> {
    return new Promise((resolve) => {
      store.commit(`vehicleManufacturerStore/${types.CREATE_ITEM.REQUEST}`)
      try {
        axios
          .post('/api/vehicleManufacturer', {
            ...vehicleManufacturer,
          })
          .then((result) => {
            store.commit(`vehicleManufacturerStore/${types.CREATE_ITEM.SUCCESS}`, result)
            if (result && result.data) {
              resolve(result.data)
            } else {
              resolve(null)
            }
          })
      } catch (err) {
        store.commit(`vehicleManufacturerStore/${types.CREATE_ITEM.FAILURE}`)
      } finally {
      }
    })
  }
}

const vehicleService = new VehicleService()
export default vehicleService

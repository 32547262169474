var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"vehicles-tables","fluid":"","tag":"section"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex",attrs:{"md":"4"}},[_c('base-material-card',{staticClass:"px-5 py-3 flex-grow-1",attrs:{"color":"indigo","icon":"mdi-factory","inline":""},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"mobile-card-heading"},[_vm._v(" "+_vm._s(_vm.$t('vehicle-manufacturer'))+" ")])]},proxy:true}])},[_c('v-text-field',{staticClass:"ml-auto responsive-table-search-input",attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search'),"hide-details":"","single-line":""},model:{value:(_vm.vehicleManufacturerSearch),callback:function ($$v) {_vm.vehicleManufacturerSearch=$$v},expression:"vehicleManufacturerSearch"}}),_c('v-divider',{staticClass:"mt-3"}),_c('v-data-table',{staticClass:"responsive-table",attrs:{"sort-by":"name","options":_vm.optionsManufacturers,"headers":_vm.vehicleManufacturerHeaders,"items":_vm.vehicleManufacturers,"footer-props":{
            'items-per-page-options': [10, 20, 50, 100],
          }},on:{"update:options":function($event){_vm.optionsManufacturers=$event}},scopedSlots:_vm._u([{key:"item",fn:function(row){return [_c('tr',{class:_vm.getHighlightClass(row.item._id),staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.selectVehicleManufacturerForFiltering(row.item)}}},[_c('td',{style:(_vm.userIsSystemAdmin && row.item.createdByUser ? 'color: orange' : '')},[_vm._v(" "+_vm._s(row.item.name)+" ")]),_c('td',{staticClass:"text-right"},[(_vm.userIsSystemAdmin && row.item.createdByUser)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [(row.item._id)?_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"small":"","color":"success"},on:{"click":function($event){return _vm.clickAcceptUserVehicleManufacturer(row.item)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-check ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.accept-vehicle-manufacturer')))])]):_vm._e(),(_vm.userIsSystemAdmin)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [(row.item._id)?_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"small":"","color":"secondary"},on:{"click":function($event){$event.stopPropagation();return _vm.clickUpdateVehicleManufacturer(row.item)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-pen ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.edit-vehicle-manufacturer')))])]):_vm._e(),(_vm.userIsSystemAdmin)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"small":"","color":"#db3236"},on:{"click":function($event){$event.stopPropagation();return _vm.clickDeleteVehicleManufacturer(row.item)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.delete-vehicle-manufacturer')))])]):_vm._e()],1)])]}}])},[_c('template',{slot:"body.prepend"},[_c('tr',{class:_vm.getHighlightClass(null),staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.selectVehicleManufacturerForFiltering(null)}}},[_c('td',{staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.$t('all'))+" ")]),_c('td')])])],2),_c('v-divider'),_c('v-row',{staticClass:"mx-auto py-6",attrs:{"justify":"end","align":"center"}},[(_vm.userIsSystemAdmin)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"green","fab":"","small":""},on:{"click":function($event){_vm.dialogManufacturer = true}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)]}}],null,false,1818519622)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.add-vehicle-manufacturer')))])]):_vm._e()],1)],1)],1),_c('v-col',{staticClass:"d-flex",attrs:{"md":"8"}},[_c('base-material-card',{staticClass:"px-5 py-3 flex-grow-1",attrs:{"color":"indigo","icon":"mdi-car","inline":""},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"mobile-card-heading"},[_vm._v(" "+_vm._s(_vm.$t('vehicles'))+" ")])]},proxy:true}])},[_c('v-text-field',{staticClass:"ml-auto responsive-table-search-input",attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search'),"hide-details":"","single-line":""},model:{value:(_vm.vehicleSearch),callback:function ($$v) {_vm.vehicleSearch=$$v},expression:"vehicleSearch"}}),_c('v-divider',{staticClass:"mt-3"}),_c('v-data-table',{staticClass:"responsive-table",attrs:{"sort-by":"name","options":_vm.options,"server-items-length":_vm.totalVehicles ? _vm.totalVehicles : _vm.vehicles.length,"headers":_vm.vehicleHeaders,"items":_vm.filteredVehicles,"footer-props":{
            'items-per-page-options': [10, 20, 50, 100],
          }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item",fn:function(row){return [_c('tr',[_c('td',{style:(_vm.userIsSystemAdmin && row.item.createdByUser ? 'color: orange' : '')},[_vm._v(" "+_vm._s(row.item.name)+" ")]),(!_vm.isMobile())?_c('td',[_vm._v(" "+_vm._s(_vm.manufacturerName(row.item.manufacturer))+" ")]):_vm._e(),_c('td',{staticClass:"text-right"},[(_vm.userIsSystemAdmin && row.item.createdByUser)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [(row.item._id)?_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"small":"","color":"success"},on:{"click":function($event){return _vm.clickAcceptUserVehicle(row.item)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-check ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.accept-vehicle')))])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [(row.item._id)?_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"small":"","color":"indigo"},on:{"click":function($event){return _vm.clickViewVehicle(row.item)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-eye ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.view-vehicle')))])]),(_vm.userIsSystemAdmin)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [(row.item._id)?_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"small":"","color":"secondary"},on:{"click":function($event){return _vm.clickUpdateVehicle(row.item)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-pen ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.edit-vehicle')))])]):_vm._e(),(_vm.userIsSystemAdmin)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"small":"","color":"#db3236"},on:{"click":function($event){return _vm.clickDeleteVehicle(row.item)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.delete-vehicle')))])]):_vm._e()],1)])]}}])}),_c('v-divider'),_c('v-row',{staticClass:"mx-auto py-6",attrs:{"justify":"end","align":"center"}},[(_vm.userIsSystemAdmin)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"green","fab":"","small":""},on:{"click":_vm.createNewVehicle}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)]}}],null,false,3991062047)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.add-vehicle')))])]):_vm._e()],1)],1)],1)],1),[(_vm.dialogManufacturer && _vm.selectedVehicleManufacturer)?_c('v-dialog',{attrs:{"width":"600"},model:{value:(_vm.dialogManufacturer),callback:function ($$v) {_vm.dialogManufacturer=$$v},expression:"dialogManufacturer"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('create-vehicle-manufacturer')))]),_c('v-card-text',[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var valid = ref.valid;
return [_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"color":"secondary","label":_vm.$t('name') + '*',"prepend-icon":"mdi-factory","validate-on-blur":""},model:{value:(_vm.selectedVehicleManufacturer.name),callback:function ($$v) {_vm.$set(_vm.selectedVehicleManufacturer, "name", $$v)},expression:"selectedVehicleManufacturer.name"}})]}}],null,true)}),_c('v-btn',{attrs:{"large":"","color":"primary","depressed":"","type":"submit","disabled":!valid},on:{"click":function($event){return _vm.clickSaveVehicleManufacturer(valid)}}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])]}}],null,false,4150914000)})],1)],1)],1):_vm._e()],[(_vm.dialog && _vm.selectedVehicle)?_c('v-dialog',{attrs:{"content-class":"vehicle-wizard-dialog"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('vehicle-wizard',{attrs:{"selected-vehicle":_vm.selectedVehicle},on:{"wizardfinished":function($event){_vm.dialog = false}}})],1):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }